import React, { useCallback } from 'react';
import { Shareable, useDeleteShareable } from '@brainstud/academy-api';
import { ConfirmationModal } from '@brainstud/ui';
import { Lock } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './ExpositionActions.module.css';

const cx = classNames.bind(styles);

export const ExpositionActionUnshare = ({
  shareable,
}: {
  shareable: Shareable;
}) => {
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();
  const [setToast] = useToaster();
  const destroy = useDeleteShareable({ shareable: shareable.id });

  const handleUnshareExposition = useCallback(() => {
    openModal(
      ConfirmationModal,
      {
        title: t('views.portfolio.exposition.unshare.confirm.title'),
        description: t('views.portfolio.exposition.unshare.confirm.question'),
        yes: t('confirm'),
        no: t('cancel'),
        handleConfirm: () =>
          destroy.mutateAsync(
            {},
            {
              onSuccess: () => {
                setToast(
                  t('views.portfolio.exposition.unshare.confirm.success'),
                  'success'
                );
                closeModal();
              },
              onError: () => {
                setToast(
                  t('views.portfolio.exposition.unshare.confirm.failure'),
                  'error'
                );
              },
            }
          ),
      },
      1
    );
  }, [closeModal, destroy, openModal, setToast, t]);

  return (
    <button
      role="menuitem"
      type="button"
      onClick={handleUnshareExposition}
      className={cx(styles.destroy)}
    >
      <Lock />
      <span>{t('unshare')}</span>
    </button>
  );
};
